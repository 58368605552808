/**
 * スクロールトリガー
 */
export class scrollTrigger {
	constructor(selector, options = {}) {
	  this.options = options;
	  this.observe(selector);
	  this.setupMutationObserver(selector);
	}
  
	observe(selector) {
	  const elements = document.querySelectorAll(selector);
	  elements.forEach((element) => {
		if (!('IntersectionObserver' in window)) {
		  this.handleIntersection(element);
		  return;
		}
		const observer = new IntersectionObserver((entries, observer) => {
		  entries.forEach((entry) => {
			if (entry.isIntersecting) {
			  this.handleIntersection(entry.target);
			  observer.unobserve(entry.target);
			}
		  });
		}, this.options);
		observer.observe(element);
	  });
	}
  
	setupMutationObserver(selector) {
	  const observer = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
		  if (mutation.addedNodes) {
			mutation.addedNodes.forEach((node) => {
			  if (node.nodeType === Node.ELEMENT_NODE && node.matches(selector)) {
				this.observe(node);
			  }
			});
		  }
		});
	  });
	  observer.observe(document.documentElement, {
		childList: true,
		subtree: true,
	  });
	}
  
	handleIntersection(element) {
	  if (this.options.cb) {
		this.options.cb(element);
	  } else {
		element.classList.add('is-enabled');
	  }
	}
}