/**
 * シンプルモーダル
 */
export class SimpleModal {
	constructor(selector, options = {}) {
		this.options = options;
		this.openBtns = document.querySelectorAll('[data-modal-target]');
		this.openBtns.forEach(openBtn => {
			const modalId = openBtn.getAttribute('data-modal-target');
			const modal = document.getElementById(modalId);
			document.body.appendChild(modal);

			// ボタンがクリックされた時
			openBtn.addEventListener('click', this.modalOpen.bind(this));
		});
  
	  // モーダルコンテンツ以外がクリックされた時
	  document.addEventListener('click', this.outsideClose.bind(this));
	}
  
	modalOpen(e) {
		const modalId = e.target.getAttribute('data-modal-target');
		const modal = document.getElementById(modalId);
  
		// ボタンがクリックされたモーダルのみをアクティブにする
		modal.classList.add('is-active');
	
		// バツ印がクリックされた時のイベントリスナーを設定
		const closeBtns = modal.querySelectorAll('[data-modal-close]');
		closeBtns.forEach(closeBtn => {
			closeBtn.addEventListener('click', this.modalClose.bind(this, modalId));
		});
	}
  
	modalClose(modalId) {
	  const modal = document.getElementById(modalId);
	  modal.classList.remove('is-active');
	}
  
	outsideClose(e) {
	  const modals = document.getElementsByClassName('js-modal');
	  Array.from(modals).forEach(modal => {
		if (e.target === modal) {
		  modal.classList.remove('is-active');
		}
	  });
	}
  }