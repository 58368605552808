/**
 * ページトップへ移動する
 */
export class pageTop {
	constructor() {
	  this.pageTopButton = document.getElementById('PageTop');
	  this.footer = document.querySelector('footer');
	  this.firstViewHeight = window.innerHeight;
	  this.isScrolling = false;
  
	  // スクロールイベントのリスナーを追加
	  this.togglePageTopButton(this.pageTopButton);
  
	  // ページトップボタンをクリックした時の動作
	  this.pageTopButton.addEventListener('click', () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	  });
	}
  
	// ページトップボタンの表示/非表示を制御する関数
	togglePageTopButton(pageTopButton) {
	  window.addEventListener('scroll', () => {
		if (!this.isScrolling) {
		  this.isScrolling = true;
		  window.requestAnimationFrame(() => {
			this.updatePageTopButtonPosition();
			this.isScrolling = false;
		  });
		}
	  });
	}
  
	// ページトップボタンの位置を更新する関数
	updatePageTopButtonPosition() {
	  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
	  const footerPosition = this.footer.getBoundingClientRect().top;
	  const threshold = 50; // 任意の閾値を設定
  
	  if (scrollPosition > this.firstViewHeight + threshold) {
		this.pageTopButton.classList.add('c-page-top-visible');
		this.pageTopButton.classList.remove('c-page-top-hidden');
	  } else {
		this.pageTopButton.classList.add('c-page-top-hidden');
		this.pageTopButton.classList.remove('c-page-top-visible');
	  }
	}
  }