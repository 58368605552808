/**
 * 複数行を丸める
 */
export class autoClamper {
	constructor(selector, options = {}){
		let els = document.querySelectorAll(selector)
		els = Array.from(els)
		els.forEach(el => {
				this.addClamp(el, options)
		})

		const trimmedClassName = selector.replace(/\./g, '');
		let clamp_readmorebtns = document.querySelectorAll('.js-auto-clamp-read-more-btn')
		clamp_readmorebtns = Array.from(clamp_readmorebtns)
		clamp_readmorebtns.forEach(elem => {
			if (!elem.classList.contains('is-auto-clamp-link')) {
				elem.addEventListener('click', () => {
					const default_btn = elem.dataset.reedMoreDefault;
					const open_btn = elem.dataset.readMoreOpen;
					const targetElement = elem.parentElement.previousElementSibling;
					if (targetElement && targetElement.classList.contains(trimmedClassName)) {
						if (!targetElement.classList.contains('is-clamp-open')) {
							targetElement.classList.add('is-clamp-open');
							elem.querySelector('span').textContent = open_btn;
						} else {
							targetElement.classList.remove('is-clamp-open');
							elem.querySelector('span').textContent = default_btn;
						}
					}
				});
			}
		})
	}
	addClamp(el, options){
		const dataLineValue = el.dataset.clampLineNumber;
		const lineNum = parseInt(dataLineValue, 10);

		if (el.offsetHeight > (lineNum+1) * parseInt(getComputedStyle(el).lineHeight)) {
			el.classList.add('is-clamp')
		}
	}
}