import { scrollTrigger } from "../../modules/scrollTrigger.js";
import { autoClamper } from "../../modules/autoClamper.js";
import { pageTop } from "../../modules/pageTop.js";
import { scrollReading } from "../../modules/scrollReading.js";
import { Parallax } from "../../modules/Parallax.js";
import { Splide } from "npm:@splidejs/splide@4.1.4";
import { SimpleModal } from '../../modules/SimpleModal.js';

const mainSlider = document.querySelector("#main-slider");
if (mainSlider) {
	const slideElements = mainSlider.querySelectorAll(".splide__slide");
	const slideCount = slideElements.length;
	if (1 < slideCount) {
		const splide = new Splide("#main-slider", {
			perPage: 1,
			type: "loop",
			padding: "0",
			focus: "center",
			updateOnMove: true,
			mediaQuery: "min",
			autoWidth: true,
			autoplay: true,
			cloneStatus: false,
			arrows: false,
			gap: "10px",
			pagination: false,
			drag: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			breakpoints: {
				1280: {
					gap: "20px",
				},
			},
		});
		// スライダーのズレ対策
		setTimeout(() => {
			splide.refresh();
		}, 200);
		splide.mount();
	} else {
		mainSlider.classList.remove("splide");
	}
}

// メッセージ内のスライダー
const messageSlider = document.querySelectorAll(".js-front-cover_slider");
if (messageSlider) {
	const $progressBar = document.querySelector(".p-front-message__progress");
	messageSlider.forEach((el) => {
		const slideElements = el.querySelectorAll(".splide__slide");
		const slideCount = slideElements.length;
		if (1 < slideCount) {
			const splide = new Splide(".js-front-cover_slider", {
				type: "loop",
				padding: "0",
				focus: "center",
				updateOnMove: true,
				fixedWidth: "1000px",
				per_page: 1,
				pagination: false,
				autoplay: true,
				classes: {
					prev: "shape-diamond__equilateral-triangle-left splide__arrow--prev",
					next: "shape-diamond__equilateral-triangle-right splide__arrow--next",
				},
				breakpoints: {
					1280: {
						heightRatio: 0.5,
						fixedWidth: false,
						arrows: false,
					},
				},
			});
			splide.mount();
			splide.on("autoplay:playing", function (rate) {
				$progressBar.style.width = rate * 100 + "%";
			});
		} else {
			el.classList.remove("splide");
		}
	});
}

// おすすめセラピストのスライダー
const therapistSlider = document.querySelectorAll(".js-therapist-slider");
if (therapistSlider) {
	therapistSlider.forEach((el) => {
		const slideElements = el.querySelectorAll(".splide__slide");
		const slideCount = slideElements.length;
		if (1 < slideCount) {
			const splide = new Splide(".js-therapist-slider", {
				type: "loop",
				padding: "0",
				focus: "center",
				updateOnMove: true,
				autoWidth: true,
				gap: "10px",
				mediaQuery: "min",
				breakpoints: {
					1280: {
						destroy: true,
					},
				},
			});
			splide.mount();
		} else {
			el.classList.remove("splide");
		}
	});
}

// セラピストの写真スライダー
const therapistPhotoSlider = document.querySelector(
	".js-therapist-profile-photo-slider"
);
if (therapistPhotoSlider) {
	const slideElements = therapistPhotoSlider.querySelectorAll(".splide__slide");
	const slideCount = slideElements.length;
	if (1 < slideCount) {
		const main_splide = new Splide(".js-therapist-profile-photo-slider", {
			type: "loop",
			padding: "0",
			focus: "center",
			updateOnMove: true,
			autoWidth: true,
			pagination: false,
			arrows: false,
			gap: "10px",
			mediaQuery: "min",
			breakpoints: {
				1280: {
					arrows: false,
				},
			},
		});
		const thumbnails_splide = new Splide(
			".js-therapist-profile-photo-thumbnail-slider",
			{
				rewind: true,
				fixedWidth: 50,
				fixedHeight: 78,
				isNavigation: true,
				gap: 10,
				pagination: false,
				cover: true,
				arrows: false,
				dragMinThreshold: {
					mouse: 4,
					touch: 10,
				},
			}
		);
		main_splide.sync(thumbnails_splide);

		main_splide.on("destroy", function () {
			// main_splide.options = { type: 'fade' };
			// main_splide.sync( thumbnails_splide );
			main_splide.refresh();
			thumbnails_splide.refresh();
		});

		main_splide.mount();
		thumbnails_splide.mount();
	} else {
		therapistPhotoSlider.classList.remove("splide");
	}
}

// スクロールアニメ
new scrollTrigger(".js-scrl-fade-up");

// 複数行を丸める
new autoClamper(".js-auto-clamp");

// ページトップ
new pageTop();

// ajax+jsonによる追加読込
new scrollReading(".js-scrl-reading-point", { threshold: "0.12" });

// dom読み込み後に実行
window.addEventListener("DOMContentLoaded", () => {
	const parallax = new Parallax();

	let timer;
	setTimeout(() => {
		timer = setInterval(() => {
			parallax.bgAnime(document.querySelectorAll(".js-parallax"));
		}, 50);
	}, 300);
});

// モーダル
new SimpleModal('.js-modal')