/**
 * スクロールによる追加読込
 */
export class scrollReading {
	constructor(selector, options = {}) {
	  this.selector = selector;
	  this.options = options;
	  this.initialize();
	}
  
	initialize() {
		const els = document.querySelectorAll(this.selector);
		els.forEach(el => {
			this.addObserver(el)
		});

		const l_els = document.querySelectorAll('.js-scrl-reading-loader-text');
		l_els.forEach(el => {
			// span要素の作成
			const text = el.textContent;
			el.textContent = '';
			for (let i = 0; i < text.length; i++) {
				let textbox = '';
				if (i < 10) {
					textbox = '<span style="animation-delay:.' + i + 's;">' + text[i] + '</span>';
				  } else {
					const n = i / 10;
					textbox = '<span style="animation-delay:' + n + 's;">' + text[i] + '</span>';
				  }
				  el.insertAdjacentHTML('beforeend', textbox)
			}
		});
	}
  
	addObserver(el) {
		let targetElem = el.previousSibling;
		while (targetElem && targetElem.nodeType !== 1) {
			targetElem = targetElem.previousSibling;
		}
		const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
			observer.unobserve(entry.target);
			this.loadMoreData(entry.target, el);
			}
		});
		}, this.options);
		observer.observe(targetElem);
	}
  
	loadMoreData(target, el) {
		let loaderElem = el.nextElementSibling;
		while (loaderElem && loaderElem.nodeType !== 1) {
			loaderElem = loaderElem.nextElementSibling;
		}
		const ajaxUrl = el.dataset.ajaxUrl;
		const page = el.dataset.page;
		if (page === '') {
			return
		}
  
		const url = ajaxUrl + page + '/';
		
		// ローダー表示
		if (loaderElem.classList.contains('is-loader-hidden')) {
			loaderElem.classList.remove('is-loader-hidden');
		}

		setTimeout(() => {
			fetch(url)
				.then(response => response.json())
				.then(data => {
					data.list.forEach(item => {
						target.insertAdjacentHTML('beforeend', item);
					});

					if (this.options.cb) {
						this.options.cb(target);
					}

					if (data.is_last == 1) {
						el.dataset.page = '';
						if (!loaderElem.classList.contains('is-loader-hidden')) {
							loaderElem.classList.add('is-loader-hidden');
						}
					} else {
						el.dataset.page = data.page;
						this.addObserver(el);
					}

					if (!loaderElem.classList.contains('is-loader-hidden')) {
						loaderElem.classList.add('is-loader-hidden');
					}
				})
				.catch(error => {
					console.error(error);
					if (!loaderElem.classList.contains('is-loader-hidden')) {
						loaderElem.classList.add('is-loader-hidden');
					}
				});
		  }, 3500);
	}
}