/**
 * シンプルモーダル
 */
export class Parallax {
	constructor() {
		this.ww;
		this.www;
		this.hh;
		this.per;
		this.timer;
		this.size_OS = 0;
		this.paraL = 0;
		this.scroll = 0;
		this.scroll2 = 0;
		this.cc = 90;
		this.bb = 180;
	}

	scrollAction(parallaxElement) {
		let scroll = document.documentElement.scrollTop;
		// Parallaxの要素をループ
		parallaxElement.forEach((el) => {
			// Parallaxの要素の位置を取得
			let targetElement = el.offsetTop;
			// ブラウザの高さを取得
			let windowHeight = document.body.clientHeight;
			// ブラウザの高さがParallaxの要素の位置を超えたら
			if (scroll > targetElement - windowHeight + 400) {
				// Parallaxの要素にクラスを付与
				el.classList.add("is-active");
			}
		});
	}

	bgAnime(parallaxElement) {
		this.scroll2 +=
			Math.floor(((this.scroll - this.scroll2) / 8) * 10000) / 10000;
		if (Math.abs(this.scroll - this.scroll2) > 0.001) {
			parallaxElement.forEach((el) => {
				// Parallaxの要素にstyleを付与
				el.style.transform = "translateY(" + -this.scroll2 * 0.4 + "px)";
			});
		}

		const this1_y = 50 * Math.sin(this.cc * 0.01 * Math.PI);
		const this2_y = 20 * -Math.sin(this.bb * 0.01 * Math.PI);

		// Parallaxの要素をループ
		parallaxElement.forEach((el) => {
			// Parallaxの要素にstyleを付与
			el.style.transform = "translateY(" + this1_y + "px)";
		});
		this.cc++;
		this.bb += 0.7;
	}
}
